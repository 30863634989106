$body = $("body");

$(document).on({
    ajaxStart: function() { $body.addClass("loading");},
    ajaxStop: function() { $body.removeClass("loading"); }
});

jQuery(document).ready(function($){
  "use strict";		
	$('#scrollTop a').on('mousedown touchend',function() {
		$("html, body").animate({ scrollTop: 0 }, "slow");
		return false;
		e.preventDefault();	
	});
	/*animate any link to #id */
	$('#mainMenu a[href^="#"]').on('mousedown touchend',function() {

		if($(this).attr('href')=='#') return  true;
	$('html,body').animate({ scrollTop: $(this.hash).offset().top}, 1000);
	  console.log($(this.hash).html());
	return false;

	e.preventDefault();

	});
	$('#scrollTop a').on('mousedown touchend',function() {
		
		
	return false;
	e.preventDefault();	
	});
	// add mouseout css class to from the blog items
	// add moueover class for items About GoFitness so that we can style from css
	$('#recent_from_the_blog .item, .fullColumnImage .item, .what-students-say .review,#meet_our_instructors .items .wrapper .item  ').on('mouseover touchend',function(){
		
		$(this).addClass('mouseover');
	});
	// on mouse over remove the mouseover class for About GoFitness
	$('#recent_from_the_blog .item,  .fullColumnImage .item , .what-students-say .review,#meet_our_instructors .items .wrapper .item ').on('mouseout',function(){
		
		$(this).removeClass('mouseover');
	});

	$('.packages .onepackage').on('mouseover touchstart',function(){
	   $(this).addClass('mouseover');
	});

	$('.packages .onepackage').on('mouseout touchend',function(){
	   $(this).removeClass('mouseover');
	});

	/*meet our theme */
	var mot_width = 0; 
	$('#meet_our_instructors .items .item').each(function(){
	mot_width += $(this).outerWidth(true);
	})
	$('#meet_our_instructors .items .wrapper').width(mot_width);

	/*contact items*/
	//poup
	$('.contact > .row .map .marker').each(function(){
		  var l=  $(this).data('x');
		  var t=  $(this).data('y');
		  $(this).css({left:l, top: t });
	  
	} );//end each
	$('.contact > .row .map .marker .fa-map-marker').each(function(){
		  if($(this).data('active') == 'yes'){
			  
			  $($(this).data('target')).fadeIn();
		  }else{
			  
			  $($(this).data('target')).fadeOut();
		  }
	});
	$('.contact > .row .map .marker .fa-map-marker').on('click touchend',function(){
		updateMarker(this)
	});

  /**
   * faq
   *    
   */
   
	$('.what-students-say .items .rows').each(function(i){
	  $(this).addClass('i'+i);
	})
	$('#what-students-say .pagination .fa').each(function(i){
	  $(this).data('target', '.i'+i);
	}) 
	$('#what-students-say .pagination .fa').on('click touchstart', function(){
	   $('.what-students-say .items .rows').hide();
	   $($(this).data('target')).fadeIn();
	})

	function updateMarker(obj){

		  if($(obj).data('active')== 'yes'){
			  $(obj).data('active','no');
			  $($(obj).data('target')).fadeOut();
		  }else{
			  $(obj).data('active','yes');
			  $($(obj).data('target')).fadeIn();
		  }
	}

	var clientSlider =$("#owl-clients");
	var meetOurInstructors = $('#meet-our-instructors .items ')
	if(clientSlider.length > 0){
		clientSlider.owlCarousel({

		  autoPlay: 3000, //Set AutoPlay to 3 seconds

		  items : 5,
		  itemsDesktop : [1199,4],
		  itemsDesktopSmall : [979,3]
		   

		});	 
		$('#clientsNav .left').on('click touchend', function(){
			clientSlider.trigger('owl.prev');
			return false;
		});
		$('#clientsNav .right').on('click touchend', function(){
			clientSlider.trigger('owl.next');
			return false;
		});
	}
	if(meetOurInstructors.length > 0){
	meetOurInstructors.owlCarousel({

	  autoPlay: 3000, //Set AutoPlay to 3 seconds

	  items : 4,
	  itemsDesktop : [1268,3],
	  itemsDesktopSmall : [1080,2],
	  itemsTablet: [768,1],
	   

	});

		$('#meet-our-instructors .navigation .left').on('click touchend', function(){
			meetOurInstructors.trigger('owl.prev');
			return false;
		});
		$('#meet-our-instructors .navigation .right').on('click touchend', function(){
			meetOurInstructors.trigger('owl.next');
			return false;

		});

	}
	
	$(window).on("scroll", function() {
		var scrollPos = $(window).scrollTop();
		if (scrollPos <= 0) {
			$("#scrollTop").fadeOut();
		} else {
			$("#scrollTop").fadeIn();
		}
	});	

			
	if($('.owl1').length >0 ) $('.owl1').owlCarousel({items: 3, autoPlay:true, stopOnHover:true, pagination:true, navigation: true}); 

});

jQuery(function($){
    $("#cpf").change(function(){
    	if(!TestCpf($(this).val().replace(/[^\d]+/g,'')))
    	{
    		swal({title: "CPF Inválido", type:"error", confirmButtonColor: "#BE8D48" });
    	}
    });

    $("#phone").mask("(99) 9999-9999");
	$("#cell").mask("(99) 9?9999-9999");
	$("#cpf").mask("999.999.999.99");
	$('#birth_date').mask("99/99/9999");
	$('#birth_date').datepicker({language: 'pt-BR', format: 'dd/mm/yyyy'});

    $("#cep").change(function(){
		var cep_code = $(this).val();
		if( cep_code.length <= 0 ) return;
		$.get("http://viacep.com.br/ws/"+cep_code+"/json/", function(address)
		{
			if(address.cep == null) {
				$("#cep").val( address.cep );
				
				$.get("/api/state-acronym/"+address.uf, function(state)
				{
					$("#state_id").val( state[0].id );
				});

				$.get("/api/city-name/"+address.localidade, function(city)
				{
					listsCities(city.state_id, city.id);
				});

				$("#district").val( address.bairro );
				$("#address").val( address.logradouro );

				$("#number").focus();
			}
		});
	});
});

function optionValues(object, id, value, type = true) {
    if (type) object.find('option').remove();
        var option = $('<option>');
    option.val(id);
    option.text(value);
    object.append(option);
}

function listsCities(stateId, cityId = null) {

	var cities = $('form select[name=city_id]');

	optionValues(cities, null, '--Selecione');

	$.getJSON('/api/city/' + stateId, function(data) {
	     
        $.each(data, function(id, name) {
            var option = $('<option>');
            option.val(id);
            option.text(name);
            if (id == cityId)
                option.attr("selected", "selected");

            cities.append(option);
        });
	});

	return false;
}

function TestCpf(cpf) 
{
	if (cpf.length != 11 || cpf == "00000000000" || cpf == "11111111111" || cpf == "22222222222" || cpf == "33333333333" || cpf == "44444444444" || cpf == "55555555555" || cpf == "66666666666" || cpf == "77777777777" || cpf == "88888888888" || cpf == "99999999999")
		return false;
	add = 0;
	for (i=0; i < 9; i ++)
		add += parseInt(cpf.charAt(i)) * (10 - i);
	rev = 11 - (add % 11);
	if (rev == 10 || rev == 11)
		rev = 0;
	if (rev != parseInt(cpf.charAt(9)))
		return false;
	add = 0;
	for (i = 0; i < 10; i ++)
		add += parseInt(cpf.charAt(i)) * (11 - i);
	rev = 11 - (add % 11);
	if (rev == 10 || rev == 11)
		rev = 0;
	if (rev != parseInt(cpf.charAt(10)))
		return false;
	
	return true;
}
